import type { FC } from 'react';
import styles from './FAQ.module.scss';
import Content from '#components/ui/Content';
import Typography from '#components/ui/Typography';
import FAQItem from './FAQItem';
import Button from '#components/ui/Button';
import { getFeatureNameStorageParam } from '#utils/getFeatureNameStorageParam';

type Props = {
  title: string;
  items: Array<{ question: string; answers: string[] }>;
  shortItems?: boolean;
  buttonTitle?: string | null;
  pathname?: string;
  locale?: string;
};

const FAQ: FC<Props> = ({ title, items, shortItems, buttonTitle, pathname, locale }) => {
  return (
    <div id="faq" className={styles.wrapper}>
      <Content>
        <div className={styles.head}>
          <Typography component="h2" variant="h2" id="questions">
            {title}
          </Typography>
        </div>
        <div className={styles.items}>
          {items.map((item, idx) => (
            <FAQItem key={item.question} initialState={!idx} {...item} shortItems={shortItems} />
          ))}
        </div>

        {buttonTitle && pathname && (
          <div className={styles.btnWrapper}>
            <Button
              text={buttonTitle}
              styleType="orange"
              customStyle="faq"
              href="email"
              currentFeature={getFeatureNameStorageParam(pathname)}
              lang={locale}
              eventName="Locate number"
            />
          </div>
        )}
      </Content>
    </div>
  );
};

export default FAQ;
