import styles from './Content.module.scss';
import type { ComponentProps, FC, PropsWithChildren } from 'react';

interface Props extends ComponentProps<'div'> {}

const Content: FC<PropsWithChildren<Props>> = ({ children, className = '', ...props }) => {
  return (
    <div className={`${styles.content} ${className}`} {...props}>
      {children}
    </div>
  );
};

export default Content;
